const { createSlice } = require('@reduxjs/toolkit');
export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		sessionStorage.setItem(`${key}`, jsonValue);
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = sessionStorage.getItem(`${key}`);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};

const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		drawer: false,
		createlogin: false,
		createflight: false,
		priceSection: true,
		selectedReturn: sessionStorage.getItem('selectedReturn')
			? JSON.parse(sessionStorage.getItem('selectedReturn'))
			: null,
		gst: false,
		term: false,
		origin: '',
		depart: '',
		originO: sessionStorage.getItem('selectedoriginO')
			? JSON.parse(sessionStorage.getItem('originO'))
			: '',
		departO: '',
		loader: false,
		flightId: 0,
		RflightId: 0,
		flightData: [],
		returnflightData: [],
		filterdataR: [],
		returnfilterdata: [],
		adult: null,
		infant: null,
		child: null,
		baggageInfo: [],
		baggageAdult1: null,
		baggageAdult2: null,
		baggageAdult3: null,
		baggageAdult4: null,
		baggageAdult5: null,
		baggageAdult6: null,
		baggageAdult7: null,
		baggageAdult8: null,
		baggageAdult9: null,
		baggageChild1: null,
		baggageChild2: null,
		baggageChild3: null,
		baggageChild4: null,
		baggageChild5: null,
		baggageChild6: null,
		mealAdult1: null,
		mealAdult2: null,
		mealAdult3: null,
		mealAdult4: null,
		mealAdult5: null,
		mealAdult6: null,
		mealAdult7: null,
		mealAdult8: null,
		mealAdult9: null,
		mealChild1: null,
		mealChild2: null,
		mealChild3: null,
		mealChild4: null,
		mealChild5: null,
		mealChild6: null,
		FormData: null,
		FormDataReturn: null,
		changelogo: false,
		trip: 'onetrip',
		selectedOrigin: sessionStorage.getItem('selectedOrigin')
			? JSON.parse(sessionStorage.getItem('selectedOrigin'))
			: null,
		selectedDepart: sessionStorage.getItem('selectedDepart')
			? JSON.parse(sessionStorage.getItem('selectedDepart'))
			: null,
		bookingformData: sessionStorage.getItem('bookingformData')
			? JSON.parse(sessionStorage.getItem('bookingformData'))
			: null,
		selectedFlight: sessionStorage.getItem('selectedFlight')
			? JSON.parse(sessionStorage.getItem('selectedFlight'))
			: null,
		errorModal: false,
		bookingAlPNR: false,
		priceChange: false,
		errorMessage: '',
		payment: '',
		successModal: false,
		successMessage: '',
		registermodal: false,
		travellermodal: false,
		etravflightFare: null,
		flightdetail: false,
		session: false,
		editticket: false,
		editprice: null,
		dob: '',
		dobC2: '',
		dobC3: '',
		bookingmessage: '',
		forgotuser: '',
		mealprice: null,
		worldline: null,
		bookingHistoryID: null,
		mealInfo: [],
		tripInfo: sessionStorage.getItem('tripInfo')
			? JSON.parse(sessionStorage.getItem('tripInfo'))
			: 'oneway',
		curr: sessionStorage.getItem('curr')
			? JSON.parse(sessionStorage.getItem('curr'))
			: 'INR',
		holidayModal: false,
		holidaySelect: null,
		selectedBus: sessionStorage.getItem('selectedBus')
			? JSON.parse(sessionStorage.getItem('selectedBus'))
			: null,
		selectedHotelDes: sessionStorage.getItem('selectedHotelDes')
			? JSON.parse(sessionStorage.getItem('selectedHotelDes'))
			: null,
		selectedDesBus: sessionStorage.getItem('selectedDesBus')
			? JSON.parse(sessionStorage.getItem('selectedDesBus'))
			: null,
		selectedTripID: sessionStorage.getItem('selectedTripID')
			? JSON.parse(sessionStorage.getItem('selectedTripID'))
			: null,
		selectedSeat: [],
		boardingPointId: sessionStorage.getItem('boardingPointId')
			? JSON.parse(sessionStorage.getItem('boardingPointId'))
			: null,
		easebuzz: false,
		modify: false,
		filt: false,
		bookingformDataReturn: sessionStorage.getItem('bookingformDataReturn')
			? JSON.parse(sessionStorage.getItem('bookingformDataReturn'))
			: null,
		section: 'flight',
		hoteltoggle: false,
		cancellationpolicy: false,
		apifailed:false,
		selectedRoomInfo: null,
		room: [1],
		adultH: [2, 2, 2, 2],
		childH: [0, 0, 0, 0],
		childAge1: [],
		childAge2: [],
		childAge3: [],
		childAge4: [],
		sidebar: 'dashboard',
		cancelModal:false,
		hotelformData: sessionStorage.getItem('hotelformData')
			? JSON.parse(sessionStorage.getItem('hotelformData'))
			: null,
		hotelbookingData: sessionStorage.getItem('hotelbookingData')
			? JSON.parse(sessionStorage.getItem('hotelbookingData'))
			: null,
			selectedBH:null

	},

	reducers: {
		setSelectedOrigin(state, action) {
			state.selectedOrigin = action.payload;
			storeData('selectedOrigin', action.payload);
		},
		setHotelFormData(state, action) {
			state.hotelformData = action.payload;
			storeData('hotelformData', action.payload);
		},
		setHotelBookingData(state, action) {
			state.hotelbookingData = action.payload;
			storeData('hotelbookingData', action.payload);
		},
		setAdultH(state, action) {
			state.adultH[action.payload.split('A')[1]] = parseInt(
				action.payload.split('A')[0]
			);
		},
		setChildH(state, action) {
			state.childH[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge1(state, action) {
			state.childAge1[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge2(state, action) {
			state.childAge2[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge3(state, action) {
			state.childAge3[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge4(state, action) {
			state.childAge4[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setSelectedRoom(state, action) {
			if (state.room.length < 4) {
				state.room = [...state.room, action.payload];
			}
		},
		removeSelectedRoom(state, action) {
			if (state.room.length === 1) {
			} else {
				let remove = state.room;
				remove.pop();
				state.room = remove;
			}
		},
		setSelectedHotel(state, action) {
			state.selectedHotelDes = action.payload;
			storeData('selectedHotelDes', action.payload);
		},
		setHotelToggle(state, action) {
			state.hoteltoggle = action.payload;
		},
		setSelectedRoomInfo(state, action) {
			state.selectedRoomInfo = action.payload;
			storeData('selectedRoomInfo', action.payload);
		},
		setSection(state, action) {
			state.section = action.payload;
		},
		setBookingFormData(state, action) {
			state.bookingformData = action.payload;
			storeData('bookingformData', action.payload);
		},
		setBookingFormDataReturn(state, action) {
			state.bookingformDataReturn = action.payload;
			storeData('bookingformDataReturn', action.payload);
		},
		setSelectedFlight(state, action) {
			state.selectedFlight = action.payload;
			storeData('selectedFlight', action.payload);
		},
		setMod(state, action) {
			state.modify = action.payload;
		},
		setSiderBar(state, action) {
			state.sidebar = action.payload;
		},
		setFilter(state, action) {
			state.filt = action.payload;
		},
		setSelectedboardingPointId(state, action) {
			state.boardingPointId = action.payload;
			storeData('boardingPointId', action.payload);
		},
		setSelectedSeat(state, action) {
			state.selectedSeat = action.payload;
		},
		setSelectedTripID(state, action) {
			state.selectedTripID = action.payload;
			storeData('selectedTripID', action.payload);
		},
		setSelectedBus(state, action) {
			state.selectedBus = action.payload;
			storeData('selectedBus', action.payload);
		},
		setSelectedDesBus(state, action) {
			state.selectedDesBus = action.payload;
			storeData('selectedDesBus', action.payload);
		},
		setSelectedHoliday(state, action) {
			state.holidaySelect = action.payload;
		},
		setSelectedCurr(state, action) {
			state.curr = action.payload;
			storeData('curr', action.payload);
		},
		setSelectedTrip(state, action) {
			state.tripInfo = action.payload;
			storeData('tripInfo', action.payload);
		},
		setForgot(state, action) {
			state.forgotuser = action.payload;
			storeData('forgotuser', action.payload);
		},
		setBookingHistoryID(state, action) {
			state.bookingHistoryID = action.payload;
		},
		setPriceSection(state, action) {
			state.priceSection = action.payload;
		},
		setMeal(state, action) {
			state.mealprice = action.payload;
		},
		setSelectedselectedReturn(state, action) {
			state.selectedReturn = action.payload;
			// storeData('selectedReturn', action.payload);
		},
		setEditPrice(state, action) {
			state.editprice = action.payload;
		},
		setEtravFlightFare(state, action) {
			state.etravflightFare = action.payload;
		},
		setSelectedDepart(state, action) {
			state.selectedDepart = action.payload;
			storeData('selectedDepart', action.payload);
		},
		toggleDrawerModal(state, action) {
			state.drawer = action.payload;
		},
		toggleEasebuzzModal(state, action) {
			state.easebuzz = action.payload;
		},
		toggleLoginModal(state, action) {
			state.createlogin = action.payload;
		},
		toggleHolidayModal(state, action) {
			state.holidayModal = action.payload;
		},
		toggleFlightModal(state, action) {
			state.createflight = action.payload;
		},
		toggleChangeLogoModal(state, action) {
			state.changelogo = action.payload;
		},
		toggleEditTicketModal(state, action) {
			state.editticket = action.payload;
		},
		toggleRegisterModal(state, action) {
			state.registermodal = action.payload;
		},
		togglePGModal(state, action) {
			state.worldline = action.payload;
		},
		toggleFlightDetailModal(state, action) {
			state.flightdetail = action.payload;
		},
		toggleRegisterTravellerModal(state, action) {
			state.travellermodal = action.payload;
		},
		toggleSessionExpiredModal(state, action) {
			state.session = action.payload;
		},
		toggleAPIFailedModal(state, action) {
			state.apifailed = action.payload;
		},
		toggleCancelModal(state, action) {
			state.cancelModal = action.payload;
		},
		toggleGSTModal(state, action) {
			state.gst = action.payload;
		},
		toggleTermModal(state, action) {
			state.term = action.payload;
		},
		setOrigin(state, action) {
			state.origin = action.payload;
		},
		setTrip(state, action) {
			state.trip = action.payload;
		},
		setOriginO(state, action) {
			state.originO = action.payload;
			storeData('originO', action.payload);
		},
		setDOB(state, action) {
			state.dob = action.payload;
		},
		setDOBC2(state, action) {
			state.dobC2 = action.payload;
		},
		setDOBC3(state, action) {
			state.dobC3 = action.payload;
		},
		setDepartO(state, action) {
			state.departO = action.payload;
		},
		setFormData(state, action) {
			state.FormData = action.payload;
		},
		setFormDataReturn(state, action) {
			state.FormDataReturn = action.payload;
		},
		setBaggage(state, action) {
			// let itemId = [...state.baggageInfo, action.payload];
			state.baggageInfo[action.payload.split('|')[0]]=action.payload
			// console.log("bag",action.payload)
			// if (state.baggageInfo?.Weight !== action.payload.Weight) {
			// 	state.baggageInfo = itemId;
			// 	// state.selectedOrderName = itemName;
			// }
			// state.baggageInfo = itemId;
		},
		setMealInfo(state, action) {
			state.mealInfo[action.payload.split('|')[0]]=action.payload
		},
		setBaggageAdult1(state, action) {
			state.baggageAdult1 = action.payload;
		},
		setBaggageAdult2(state, action) {
			state.baggageAdult2 = action.payload;
		},
		setBaggageAdult3(state, action) {
			state.baggageAdult3 = action.payload;
		},
		setBaggageAdult4(state, action) {
			state.baggageAdult4 = action.payload;
		},
		setBaggageAdult5(state, action) {
			state.baggageAdult5 = action.payload;
		},
		setBaggageAdult6(state, action) {
			state.baggageAdult6 = action.payload;
		},
		setBaggageAdult7(state, action) {
			state.baggageAdult7 = action.payload;
		},
		setBaggageAdult8(state, action) {
			state.baggageAdult8 = action.payload;
		},
		setBaggageAdult9(state, action) {
			state.baggageAdult9 = action.payload;
		},
		setBaggageChild1(state, action) {
			state.baggageChild1 = action.payload;
		},
		setBaggageChild2(state, action) {
			state.baggageChild2 = action.payload;
		},
		setBaggageChild3(state, action) {
			state.baggageChild3 = action.payload;
		},
		setBaggageChild4(state, action) {
			state.baggageChild4 = action.payload;
		},
		setBaggageChild5(state, action) {
			state.baggageChild5 = action.payload;
		},
		setBaggageChild6(state, action) {
			state.baggageChild6 = action.payload;
		},
		setMealAdult1(state, action) {
			state.mealAdult1 = action.payload;
		},
		setMealAdult2(state, action) {
			state.mealAdult2 = action.payload;
		},
		setMealAdult3(state, action) {
			state.mealAdult3 = action.payload;
		},
		setMealAdult4(state, action) {
			state.mealAdult4 = action.payload;
		},
		setMealAdult5(state, action) {
			state.mealAdult5 = action.payload;
		},
		setMealAdult6(state, action) {
			state.mealAdult6 = action.payload;
		},
		setMealAdult7(state, action) {
			state.mealAdult7 = action.payload;
		},
		setMealAdult8(state, action) {
			state.mealAdult8 = action.payload;
		},
		setMealAdult9(state, action) {
			state.mealAdult9 = action.payload;
		},
		setMealChild1(state, action) {
			state.mealChild1 = action.payload;
		},
		setMealChild2(state, action) {
			state.mealChild2 = action.payload;
		},
		setMealChild3(state, action) {
			state.mealChild3 = action.payload;
		},
		setMealChild4(state, action) {
			state.mealChild4 = action.payload;
		},
		setMealChild5(state, action) {
			state.mealChild5 = action.payload;
		},
		setMealChild6(state, action) {
			state.mealChild6 = action.payload;
		},
		setAdult1(state, action) {
			state.adult = action.payload;
		},
		setInfant1(state, action) {
			state.infant = action.payload;
		},
		setChild1(state, action) {
			state.child = action.payload;
		},
		setFlightData(state, action) {
			state.flightData = action.payload;
		},
		setReturnFilterData(state, action) {
			state.returnfilterdata = action.payload;
		},
		setReturnFlightData(state, action) {
			state.returnflightData = action.payload;
		},
		setFilterData(state, action) {
			state.filterdataR = action.payload;
		},
		setDepart(state, action) {
			state.depart = action.payload;
		},
		setFlightId(state, action) {
			state.flightId = action.payload;
		},
		setReturnFlightId(state, action) {
			state.RflightId = action.payload;
		},
		loader(state, action) {
			state.loader = action.payload;
		},
		toggleErrorModal(state, action) {
			state.errorModal = action.payload;
		},
		toggleBPNRModal(state, action) {
			state.bookingAlPNR = action.payload;
		},
		togglePriceChangeModal(state, action) {
			state.priceChange = action.payload;
		},
		toggleCancellationPolicyModal(state, action) {
			state.cancellationpolicy = action.payload;
		},
		toggleSuccessModal(state, action) {
			state.successModal = action.payload;
		},
		setErrorMessage(state, action) {
			state.errorMessage = action.payload;
		},
		setBookingMessage(state, action) {
			state.bookingmessage = action.payload;
		},
		setPayment(state, action) {
			state.payment = action.payload;
		},
		setSuccessMessage(state, action) {
			state.successMessage = action.payload;
		},
		setSelectedBookingHistory(state,action){
			state.selectedBH = action.payload;
		},
	},
});

const { actions } = UiReducer;

export const {
	setSuccessMessage,
	setSelectedRoom,
	toggleAPIFailedModal,
	setSelectedRoomInfo,
	setHotelFormData,
	setHotelBookingData,
	toggleCancelModal,
	setAdultH,
	setSelectedBookingHistory,
	setSiderBar,
	setChildH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
	removeSelectedRoom,
	setBookingMessage,
	toggleCancellationPolicyModal,
	setEtravFlightFare,
	setSelectedBus,
	setSelectedFlight,
	setSelectedboardingPointId,
	setSelectedDesBus,
	setBookingFormDataReturn,
	toggleEasebuzzModal,
	setSelectedselectedReturn,
	toggleRegisterModal,
	setSelectedTrip,
	togglePriceChangeModal,
	setBookingHistoryID,
	toggleDrawerModal,
	toggleLoginModal,
	toggleSuccessModal,
	toggleFlightModal,
	toggleGSTModal,
	toggleEditTicketModal,
	toggleSessionExpiredModal,
	setOrigin,
	setMealInfo,
	togglePGModal,
	setForgot,
	setMeal,
	toggleBPNRModal,
	setOriginO,
	setDOB,
	setDOBC2,
	setDOBC3,
	toggleFlightDetailModal,
	setDepartO,
	setFlightData,
	setPayment,
	setReturnFlightData,
	setDepart,
	loader,
	setFlightId,
	setEditPrice,
	setReturnFlightId,
	toggleTermModal,
	setFilterData,
	toggleChangeLogoModal,
	setReturnFilterData,
	setAdult1,
	setInfant1,
	setChild1,
	setBaggage,
	toggleRegisterTravellerModal,
	setBaggageAdult1,
	setBaggageAdult2,
	setBaggageAdult3,
	setBaggageAdult4,
	setBaggageAdult5,
	setBaggageAdult6,
	setBaggageAdult7,
	setBaggageAdult8,
	setBaggageAdult9,
	setBaggageChild1,
	setBaggageChild2,
	setBaggageChild3,
	setBaggageChild4,
	setBaggageChild5,
	setBaggageChild6,
	setBaggageChild7,
	setBaggageChild8,
	setBaggageChild9,
	setMealAdult1,
	setMealAdult2,
	setMealAdult3,
	setMealAdult4,
	setMealAdult5,
	setMealAdult6,
	setMealAdult7,
	setMealAdult8,
	setMealAdult9,
	setMealChild1,
	setMealChild2,
	setMealChild3,
	setMealChild4,
	setMealChild5,
	setMealChild6,
	setFormData,
	setFormDataReturn,
	setTrip,
	setSelectedOrigin,
	setSelectedDepart,
	toggleErrorModal,
	setErrorMessage,
	setSelectedCurr,
	setPriceSection,
	toggleHolidayModal,
	setSelectedHoliday,
	setSelectedTripID,
	setSelectedSeat,
	setMod,
	setFilter,
	setBookingFormData,
	setSection,
	setSelectedHotel,
	setHotelToggle,
} = actions;

export default UiReducer;
