import React from 'react';
import {
	Dialog,
	DialogContent,
	makeStyles,
	Grid,
	Button,
	Box
} from '@material-ui/core';
import ErrorModalIcon from '../../assets/images/ErrorModalIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
	toggleErrorModal,
	setErrorMessage,
	toggleBPNRModal,
	toggleSessionExpiredModal,
	setPriceSection
} from '../../reducers/UiReducer';
// import HeadlineText from './HeadlineText';
import BodyText from './BodyText';
import { useHistory, useLocation } from 'react-router-dom';
import TitleText from './TitleText';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
}));

const APIFailedErrorModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { agentlogin } = useSelector((state) => state.user);
	const { apifailed, errorMessage, priceChange, bookingmessage,selectedOrigin,selectedDepart,origin } =
		useSelector((state) => state.ui);
	const {fareQuoteEtrav}= useSelector((state)=>state.home);
	const handleClose = () => {
		dispatch(toggleBPNRModal(false));

		dispatch(setErrorMessage(''));
	};
	return (
		<Dialog
			PaperProps={{
				style: {
					borderRadius: 20,
				},
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			// onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={apifailed}
			fullWidth={true}
			maxWidth={'sm'}>
			<DialogContent
				style={{
					paddingBottom: 20,
					paddingTop: 50,
				}}>
				<TitleText textStyle={{ fontWeight: 700, textAlign: 'center' }}>
					Oops!
				</TitleText>
				<h2 style={{ textAlign: 'center', color: 'rgb(239, 51, 57)' }}>
					!! Technical Error from Airline End.  !!
				</h2>
				<h4 style={{ textAlign: 'center', color: 'rgb(239, 51, 57)' }}>Kindly refresh and try again</h4>
				
			</DialogContent>
			
			<Grid container style={{ marginTop: 5 }}>
				<Grid item xs={2}></Grid>
				<Grid item xs={8}>
					<Button
						className={classes.createButton}
						onClick={() => history.push({
							pathname: '/',
						  })}
						style={{ marginTop: 5, fontSize: 14 }}
					>
						BACK TO HOME
					</Button>
				</Grid>
				<Grid item xs={2}></Grid>
			</Grid>
		</Dialog>
	);
};

export default APIFailedErrorModal;
