import React from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Avatar,
	Chip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Slider,
	withStyles,
	FormControlLabel,
	Checkbox,
	Divider,
	useTheme,useMediaQuery
} from '@material-ui/core';
import TitleText from './TitleText';
import BodyText from './BodyText';
import { useLocation } from 'react-router-dom';
function convertH2M(timeInHour) {
	// console.log(timeInHour);
	var timeParts = timeInHour.split(':');
	return Number(timeParts[0]) * 60 + Number(timeParts[1]);
}
function timeConvert(n) {
	var num = n;
	var hours = num / 60;
	var rhours = Math.floor(hours);
	var minutes = (hours - rhours) * 60;
	var rminutes = Math.round(minutes);
	return rhours + ' hr and ' + rminutes + ' min.';
}
const SelectedOnwardTJKRow = ({ i, index, selectedonward }) => {
	const location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("xs"));
	return (
		<>
		<Box
		  display="flex"
		  justifyContent="space-between"
		  mt={index > 0 ? "20px" : ""}
		>
		  <Box display="flex" alignItems="center">
			<Box alignItems="center" display="flex">
			  <TitleText
				textStyle={{
				  fontFamily: "unset",
				  fontWeight: 500,
				  fontSize: 18,
				}}
			  >
				{i.da.cityCode}
			  </TitleText>
			  -
			  <TitleText
				textStyle={{
				  fontFamily: "unset",
				  fontWeight: 500,
				  fontSize: 18,
				}}
			  >
				{i.aa.cityCode}
			  </TitleText>
			</Box>
			<Box
			  ml={"30px"}
			  style={{
				background: "rgb(239, 51, 57)",
				padding: "3px 15px",
				borderRadius: "50px",
				color: "white",
				fontFamily: "unset",
				fontWeight: 500,
			  }}
			>
			  <TitleText two>RETURN</TitleText>
			</Box>
		  </Box>
		  <Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
		</Box>
		<Box mt={"20px"}>
		  <BodyText three textStyle={{ fontSize: 16 }}>
			{" "}
			{/* {`${new Date(
			  location.state.returnBody.Segments[0].PreferredDepartureTime
			).toLocaleString("en-us", {
			  weekday: "short",
			})} ,  ${new Date(
			  location.state.returnBody.Segments[0].PreferredDepartureTime
			).getDate()} ${new Date(
			  location.state.returnBody.Segments[0].PreferredDepartureTime
			).toLocaleString("en-us", {
			  month: "short",
			})},2022`} */}
		  </BodyText>
		</Box>
		<Box
		  style={{
			background: "white",
			padding: "10px 15px",
			border: "1px solid #e6e6e6",
			marginTop: "10px",
			borderRadius: 5,
		  }}
		>
		  <Grid container style={{ alignItems: "center" }}>
			<Grid item xs={12} sm={3} lg={3}>
			  <Box
				display={matches ? "flex" : ""}
				justifyContent={
				  matches ? "space-between" : ""
				}
				alignItems={matches ? "center" : ""}
			  >
				<TitleText
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 500,
					fontSize: 18,
				  }}
				>
				  {i.da.city}
				  {/* {i.Origin.Airport.AirportCode}) */}
				</TitleText>
				<TitleText
				  three
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 600,
					fontSize: matches ? 16 : "",
				  }}
				>
				  {i.dt.substr(11, 5)}
				</TitleText>
			  </Box>
			</Grid>
			<Grid
			  item
			  xs={12}
			  sm={3}
			  lg={3}
			  style={{ marginTop: matches ? 10 : "" }}
			>
			  <Box
				style={{
				  padding: "2px 20px",
				  background: "rgb(239, 51, 57)",
				  color: "white",
				  borderRadius: 25,
				}}
			  >
				<BodyText
				  three
				  textStyle={{
					fontSize: 16,
					textAlign: "center",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				  }}
				>
				  {timeConvert(i.duration)}
				</BodyText>
			  </Box>
			</Grid>
			<Grid
			  item
			  xs={12}
			  sm={3}
			  lg={3}
			  style={{
				textAlign: "center",
				marginTop: matches ? 10 : "",
			  }}
			>
			  <Box
				display={matches ? "flex" : ""}
				justifyContent={
				  matches ? "space-between" : ""
				}
				alignItems={matches ? "center" : ""}
			  >
				<TitleText
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 500,
					fontSize: 18,
				  }}
				>
				  {i.aa.cityCode}
				  {/* {i.Destination_City} */}
				  {/* {i.Origin.Airport.AirportCode}) */}
				</TitleText>
				<TitleText
				  three
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 500,
					fontSize: matches ? 16 : "",
				  }}
				>
				  {i.at.substr(11, 5)}
				</TitleText>
			  </Box>
			</Grid>
			<Grid
			  item
			  xs={12}
			  sm={3}
			  lg={3}
			  style={{ textAlign: "center" }}
			>
			  <img
				src={`https://nitish.musafirbazar.com/static/media/${i.fD.aI.code}.gif`}
				width="50px"
				height="25px"
			  ></img>
			  <p>{`${i.fD.aI.name} (${i.fD.aI.code}-${i.fD.fN})`}</p>
			</Grid>
		  </Grid>
		  {/* <Grid container>
			<Grid item xs={12} sm={9} lg={9}>
			  <Box
				display="flex"
				justifyContent="space-between"
			  >
				<Box>
				  <TitleText
					textStyle={{
					  fontFamily: "unset",
					  fontWeight: 500,
					  fontSize: 18,
					}}
				  >
					{i.Origin.Airport.CityName}(
					{i.Origin.Airport.AirportCode})
				  </TitleText>
				  <BodyText
					four
					textStyle={{
					  fontFamily: "unset",
					  fontWeight: 500,
					}}
				  >
					{i.Origin.Airport.AirportName}
				  </BodyText>
				</Box>
				<Box>
				  <TitleText
					four
					textStyle={{
					  fontFamily: "unset",
					  fontWeight: 500,
					}}
				  >
					{i.Origin.DepTime.substr(11, 5)}
				  </TitleText>
				</Box>
			  </Box>
			  <Grid container alignItems="center">
				<Grid item xs={6} lg={9}>
				  <Divider />
				</Grid>
				<Grid item xs={4} lg={3}>
				  <Box
					style={{
					  padding: "2px 20px",
					  background: "rgb(239, 51, 57)",
					  color: "white",
					  borderRadius: 5,
					}}
				  >
					<BodyText three>
					  {timeConvert(i.Duration)}
					</BodyText>
				  </Box>
				</Grid>
			  </Grid>

			  <Box
				display="flex"
				justifyContent="space-between"
			  >
				<Box>
				  <TitleText
					textStyle={{
					  fontFamily: "unset",
					  fontWeight: 500,
					  fontSize: 18,
					}}
				  >
					{i.Destination.Airport.CityName}(
					{i.Destination.Airport.AirportCode})
				  </TitleText>
				  <BodyText
					four
					textStyle={{
					  fontFamily: "unset",
					  fontWeight: 500,
					}}
				  >
					{i.Destination.Airport.AirportName}
				  </BodyText>
				</Box>
				<Box>
				  <TitleText
					four
					textStyle={{
					  fontFamily: "unset",
					  fontWeight: 500,
					}}
				  >
					{i.Destination.ArrTime.substr(11, 5)}
				  </TitleText>
				</Box>
			  </Box>
			</Grid>
			<Grid item xs={12} sm={3} lg={3}>
			  <Box textAlign="center">
				<img
				  src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
				  width="50px"
				  height="25px"
				></img>
				<BodyText three>
				  {i.Airline.AirlineName}
				</BodyText>
				<BodyText three>
				  {i.Airline.AirlineCode}-
				  {i.Airline.FlightNumber}| Economy
				</BodyText>
				<BodyText three>
				  Check-In : 1Pc - 15Kgs
				</BodyText>
				<BodyText three>Cabin : 7Kgs</BodyText>
			  </Box>
			</Grid>
		  </Grid> */}
		</Box>
	  </>
	);
};

export default SelectedOnwardTJKRow;
